import clevertap from '.'

import { cleanEmpty } from 'utils/helpers/removeEmptyValues'
import { transformPayload } from './helpers/transformPayload'
import { getPercent } from 'utils/helpers/getPercent'
import { get } from 'lodash'
import { calculateDiscountPercent } from 'components/Product/ProductListItem/components/Price/helper'
export const ctEvent = {
  login: 'Login',
  signup: 'Sign Up',
  addToCart: 'Add to Cart',
  charged: 'Charged',
  viewItem: 'View Item',
  removeSkuFromCart: 'Remove SKU From Cart',
  viewCart: 'View Cart',
  applyMembershipCard: 'Apply Membership Card',
  renewMembershipCard: 'Renew Membership Card',
  webSessionStarted: 'Web Session Started',
}
export const ctEventPush = (eventName, payload) => {
  clevertap.event.push(eventName, payload)
}

export const ctViewItem = (productDetail = {}) => {
  ctEventPush(ctEvent.viewItem, cleanEmpty(transformPayload(productDetail)))
}

export const ctRemoveSkuFromCart = (
  productDetail = {},
  isFromCartPage = false,
) => {
  let payload = {
    ...transformPayload(productDetail),
    quantity: productDetail.qty,
  }
  if (isFromCartPage) {
    const originalPrice = get(
      productDetail,
      'extension_attributes.product_data.price',
      0,
    )
    const finalPrice = productDetail?.price
    const percentDiscount = calculateDiscountPercent(originalPrice, finalPrice)
    payload = {
      ...payload,
      sku_price_regular: originalPrice,
      sku_price_special: productDetail?.price,
      sku_price_discount: `${percentDiscount}%`,
    }
  }

  ctEventPush(ctEvent.removeSkuFromCart, cleanEmpty(payload))
}

export const ctAddToCart = (productDetail = {}) => {
  const regular_price = productDetail?.price || ''
  const special_price = productDetail?.extension_attributes?.final_price || ''
  const brand = productDetail?.extension_attributes?.brand || ''
  let payload = {
    sku_id: productDetail?.sku,
    sku_name: productDetail?.name,
    quantity: Math.abs(productDetail?.qty),
    sku_price_regular: regular_price,
    sku_price_special: special_price,
    sku_price_discount: getPercent(regular_price, special_price),
    brand: brand,
  }

  ctEventPush(ctEvent.addToCart, cleanEmpty(payload))
}

export const ctViewCart = (productDetail = {}) => {
  let skus = []
  const items = productDetail?.items
  const subTotal = parseFloat(productDetail?.subtotal).toFixed(2)
  const total = parseFloat(productDetail?.total).toFixed(2)

  try {
    items.forEach((item) => {
      skus.push(item?.sku)
    })
    const skuStr = skus.join(',')

    if (skuStr.length <= 510) {
      const payload = {
        sku_ids: skuStr,
        cart_subtotal: subTotal,
        cart_total: total,
      }
      ctEventPush(ctEvent.viewCart, cleanEmpty(payload))
    }
  } catch (error) {}
}
