import { produce } from 'immer'
import { setStoreCode } from 'utils/request'
import * as constants from './constants'
import { get, assign, cloneDeep, set } from 'lodash'
import * as authConstant from 'containers/Authentication/constants'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'
import { getPathFromFullUrl } from 'helper/urlHelper'
import { getStoreViewCode } from 'utils/products'
import { setStoreView } from 'utils/request'
import { setUserEmailApi } from 'api/utils'

const persistConfig = {
  key: 'global',
  storage: localStorage,
  whitelist: [
    'accessToken',
    'currentLocation',
    'listProvince',
    'listCity',
    'listBarangay',
    'storeAddresses',
    'sideMenu',
    'sideAds',
    'specialCategoryID',
    'storeViewSources',
    'isShowChatNudge',
    'yellowAiPayload',
    'currentUserEmail',
  ],
  debounce: 3000,
}

const getMenuLinks = (arr = []) => {
  return arr.map((item) => {
    return getPathFromFullUrl(item.url)
  })
}

const getSubMenuLinks = (arr = []) => {
  return arr.map((item) => {
    return item.children.map((i) => {
      return getPathFromFullUrl(i.url)
    })
  })
}

const INITIAL_YELLOW_AI_PAYLOAD = {
  login: 'no',
  userEmail: '',
  name: '',
}

export const initialState = {
  currentLocation: {},
  isLoading: false,
  isLoadingUserInfo: false,
  isLoadingDeliveryLocation: false,
  sideBar: [],
  isSideBarLoaded: false,
  sideMenu: [],
  subMenu: [],
  sideAds: [],
  isShowSideBar: false,
  hasLoader: false,
  hasPartialLoader: {},
  accessToken: '',
  currentUser: {},
  currentUserEmail: '',
  listProvince: [],
  listCity: [],
  listBarangay: [],
  footer: [],
  isLoadingFooter: false,
  loadFooterError: false,
  error: {},
  isSearchMode: false,
  persistorLoaded: false,
  countryName: '',
  cms: [],
  isCmsLoaded: false,
  globalConfig: {
    isLoading: false,
    homepage_slug: 'new-home',
  },
  storeAddresses: null,
  specialCategoryID: 0,
  outOfStockConfig: null,
  footerConfig: null,
  preferredBranches: [],
  deliverySlotVerbiage: null,
  searchAndAutoSuggest: {},
  storeViewSources: [],
  isShowChatNudge: true,
  yellowAiPayload: INITIAL_YELLOW_AI_PAYLOAD,
}

const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case constants.SHOW_LOADER:
        newState.hasLoader = action.payload
        break
      case constants.HIDE_LOADER:
        newState.hasLoader = false
        break

      case constants.SHOW_PARTIAL_LOADER:
        const hasPartialLoader = cloneDeep(state.hasPartialLoader)
        newState.hasPartialLoader = assign(hasPartialLoader, action.payload)
        break
      case constants.LOAD_CATEGORY_MENU_LEFT_SUCCESS:
        newState.sideMenu = action.payload
        newState.sideMenuLinks = getMenuLinks(action.payload)
        newState.subMenuLinks = getSubMenuLinks(action.payload)
        break
      case constants.LOAD_SIDEADS_SUCCESS:
        newState.sideAds = action.payload
        break
      // LOAD_SIDEBAR
      case constants.LOAD_SIDEBAR:
        break
      case constants.LOAD_SIDEBAR_SUCCESS:
        newState.sideBar = action.payload
        newState.isSideBarLoaded = true
        break
      case constants.LOAD_SIDEBAR_FAILED:
        break

      // LOAD_SUBMENUTOP
      case constants.LOAD_SUBMENUTOP:
        break
      case constants.LOAD_SUBMENUTOP_SUCCESS:
        newState.subMenuTop = action.payload
        break
      case constants.LOAD_SUBMENUTOP_FAILED:
        break

      // CHECK_LOCATION
      case constants.CHECK_LOCATION:
        break
      case constants.CHECK_LOCATION_SUCCESS:
        let storeCode = get(
          action,
          'payload.barangay.extension_attributes.stock_source_code',
        )
        if (get(action, 'payload.id') || get(action, 'payload.region_id')) {
          storeCode =
            get(
              action,
              'payload.extension_attributes.address_validation_data.source_code',
            ) ||
            get(
              action,
              'payload.extension_attributes.address_validation_data.validate_cart_data.source_stock_code',
            )
        }
        if (action.payload?.datastore) {
          const storeCode = action.payload?.datastore?.stock_source_code
          set(action, 'payload.storeCode', storeCode)
          setStoreCode(storeCode)
          setStoreView(getStoreViewCode(storeCode, state.storeViewSources))
          newState.currentLocation = {
            datastore: {
              ...action.payload?.datastore,
              township_id: +action.payload?.datastore?.township_id,
              region_id: +action.payload?.datastore?.region_id,
            },
            storeCode,
            storeaddress: action.payload?.storeaddress,
          }
        } else {
          if (storeCode) {
            set(action, 'payload.storeCode', storeCode)
            setStoreCode(storeCode)
            setStoreView(getStoreViewCode(storeCode, state.storeViewSources))
            newState.currentLocation = action.payload
          }
        }
        break
      case constants.CHECK_LOCATION_FAILED:
        break

      case constants.CLEAR_CURRENT_LOCATION:
        newState.currentLocation = {}
        break

      // LOGIN
      case authConstant.LOGIN:
        break
      case authConstant.LOGIN_SUCCESS:
        newState.accessToken = get(action, 'payload.access_token')
        break
      case authConstant.LOGIN_FAILED:
        break

      // LOGIN SOCIAL
      case authConstant.LOGIN_SOCIAL:
        break
      case authConstant.LOGIN_SOCIAL_SUCCESS:
        newState.accessToken = action.payload
        break
      case authConstant.LOGIN_SOCIAL_FAILED:
        break

      case constants.GET_USER_INFO:
        newState.isLoadingUserInfo = action?.isShowLoading
        break
      case constants.GET_USER_INFO_SUCCESS:
        newState.currentUser = action.payload
        newState.currentUserEmail = action.payload.email
        newState.isLoadingUserInfo = false
        setUserEmailApi(action.payload.email)
        break
      case constants.GET_USER_INFO_FAILED:
        newState.isLoadingUserInfo = false
        break
      //ADDRESS MODAL
      case constants.LOAD_PROVINCE:
        newState.isLoadingDeliveryLocation = true
        break
      case constants.LOAD_PROVINCE_SUCCESS:
        newState.isLoadingDeliveryLocation = false
        newState.listProvince = action.payload
        break
      case constants.LOAD_PROVINCE_FAILED:
        newState.isLoadingDeliveryLocation = false
        newState.error = action.payload
        break
      case constants.LOAD_CITY:
        newState.isLoadingDeliveryLocation = true
        break
      case constants.LOAD_CITY_SUCCESS:
        newState.isLoadingDeliveryLocation = false
        newState.listCity = action.payload
        break
      case constants.LOAD_CITY_FAILED:
        newState.isLoadingDeliveryLocation = false
        newState.error = action.payload
        break
      case constants.LOAD_BARANGAY:
        newState.isLoadingDeliveryLocation = true
        break
      case constants.LOAD_BARANGAY_SUCCESS:
        newState.isLoadingDeliveryLocation = false
        newState.listBarangay = action.payload
        break
      case constants.LOAD_BARANGAY_FAILED:
        newState.isLoadingDeliveryLocation = false
        newState.error = action.payload
        break

      //LOAD_FOOTER
      case constants.LOAD_FOOTER_REQUEST:
        newState.isLoadingFooter = true
        newState.loadFooterError = false
        break
      case constants.LOAD_FOOTER_SUCCESS:
        newState.footer = action.payload
        newState.isLoadingFooter = false
        break
      case constants.LOAD_FOOTER_FAILED:
        newState.error = action.payload
        newState.loadFooterError = true
        newState.isLoadingFooter = false
        break

      //LOAD_CMS
      case constants.LOAD_ALL_CMS:
        break
      case constants.LOAD_ALL_CMS_SUCCESS:
        newState.cms = action.payload
        newState.isCmsLoaded = true
        break
      case constants.LOAD_ALL_CMS_FAILED:
        newState.error = action.payload
        break

      // SHOW_SIDE_BAR
      case constants.SHOW_SIDE_BAR:
        newState.isShowSideBar = action.payload
        break

      case constants.HIDE_SIDE_BAR:
        newState.isShowSideBar = false
        break
      // SHOW SEARCH MODE
      case constants.SHOW_SEARCH_MODE:
        newState.isSearchMode = action.payload
        break

      // SUBSCRIBE_PRODUCT
      case constants.SUBSCRIBE_PRODUCT:
        break
      case constants.SUBSCRIBE_PRODUCT_SUCCESS:
        newState.subscribeProduct = action.payload
        break
      case constants.SUBSCRIBE_PRODUCT_FAILED:
        newState.subscribeProduct = {}
        break

      // LOGOUT
      case constants.LOGOUT:
        if(action.payload && action.payload.supported_account) {
          newState.accessToken = sessionStorage.getItem("support_token")
        }
        else {
          newState.accessToken = ''
        }
        newState.allAddresses = []
        newState.allOrders = {}
        newState.currentOrders = {}
        newState.currentUser = {}
        newState.yellowAiPayload = INITIAL_YELLOW_AI_PAYLOAD
        break

      case constants.SET_PERSISTOR_LOADED:
        newState.persistorLoaded = true
        break

      case constants.LOAD_COUNTRY_NAME_SUCCESS:
        newState.countryName = action.payload
        break

      case authConstant.SIGN_UP_SUCCESS:
        newState.accessToken = get(action, 'payload.access_token')
        break
      case constants.LOAD_GLOBAL_CONFIG:
        newState.globalConfig = { ...state.globalConfig, isLoading: true }
        break
      case constants.LOAD_GLOBAL_CONFIG_SUCCESS:
        newState.globalConfig = { ...action.payload, isLoading: false }
        break
      case constants.LOAD_GLOBAL_CONFIG_FAILED:
        newState.globalConfig = {
          homepage_slug: 'new-home',
          isLoading: false,
        }
        break
      case constants.LOAD_STORE_ADDRESSES:
        break
      case constants.LOAD_STORE_ADDRESSES_SUCCESS:
        newState.storeAddresses = action.payload
        break
      case constants.LOAD_SPECIAL_CATEGORY_ID_SUCCESS:
        newState.specialCategoryID = action.payload
        break
      case constants.LOAD_STORE_ADDRESSES_FAILED:
        break
      case constants.LOAD_OUT_OF_STOCK_CONFIG:
        newState.outOfStockConfig = action.payload
        break
      case constants.LOAD_FOOTER_CONFIG:
        newState.footerConfig = action.payload
        break
      case constants.LOAD_PREFERRED_BRANCHES:
        newState.preferredBranches = action.payload
        break
      case constants.LOAD_DELIVERY_SLOT_VERBIAGE:
        newState.deliverySlotVerbiage = action.payload
        break
      case constants.LOAD_SEARCH_AND_AUTO_SUGGEST:
        newState.searchAndAutoSuggest = action.payload
        break
      case constants.SET_STORE_VIEW_MAPPING_SUCCESS:
        newState.storeViewSources = action.payload
        break
      case constants.SET_IS_SHOW_CHAT_NUDGE:
        newState.isShowChatNudge = action.payload
        break
      case constants.SET_YELLOW_AI_PAYLOAD_SUCCESS:
        newState.yellowAiPayload = action.payload
        break
      default:
        break
    }
  })

export default persistReducer(persistConfig, reducer)
